import { render, staticRenderFns } from "./DocumentosEmpresaMainView.vue?vue&type=template&id=42e1f626"
import script from "./DocumentosEmpresaMainView.vue?vue&type=script&lang=js"
export * from "./DocumentosEmpresaMainView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports