<template>
  <div>
    <div v-if="isThereData">
      <b-pagination size="sm" align="fill"
                    v-model="currentPage"
                    :total-rows="getTotalItems"
                    :per-page="perPage"
                    :disabled="!getAvailableList"
                    aria-controls="inversionistas-table"
      ></b-pagination>
    </div>
    <!-- Table -->
    <b-table
      id="documentosEmpresaTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron documentos con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(empresa.razon_social)="row">
        <filtered-row :value="row.item.empresa.razon_social" :filter="filter"/>
      </template>

      <template #cell(identificacion)="row">
        <filtered-row :value="row.item.identificacion" :filter="filter"/>
      </template>

      <template #cell(archivos)="row">
        <span class="text-sm text-left w-100">
          <ul>
            <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
              <b-link @click="onDownloadFile(archivo)">
                <filtered-row v-if="archivo" :value="archivo.archivo" :filter="filter"/>
              </b-link>
            </li>
          </ul>
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="documentosEmpresaModule/getResourceToEdit"
            deletePath="documentosEmpresaModule/deleteResource"
            :editPermissions="['actualizar documentos']"
            :deletePermissions="['eliminar documentos']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Información del pago"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item v-if="row.item.tipo_persona">
                      <b>Tipo de persona:</b>
                      {{ row.item.tipo_persona }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.rfc">
                      <b>RFC:</b>
                      {{ row.item.rfc }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.regimen_fiscal.descripcion">
                      <b>Régimen fiscal:</b>
                      {{ row.item.regimen_fiscal.descripcion }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.identificacion">
                      <b>Identificación:</b>
                      {{ row.item.identificacion }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.tipo_persona">
                      <b>{{ row.item.tipo_persona == 'FISICA' ? 'Nombre completo' : 'Razón social' }}:</b>
                      {{ row.item.tipo_persona == 'FISICA' ? row.item.persona_fisica.nombre_completo : row.item.persona_moral.razon_social }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'

const API_MODULE = 'documentosEmpresaModule'

export default {
  name: 'DocumentosList',

  components: {
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'empresa.razon_social', label: 'Empresa', sortable: true },
        { key: 'identificacion', label: 'Documento' },
        { key: 'archivos', label: 'Archivos' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['persona.nombre_completo', 'email']
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Documentos')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.empresa.razon_social.toLowerCase().includes(filter.toLowerCase()) ||
        row.identificacion.toLowerCase().includes(filter.toLowerCase()) ||
        row.archivos.some((element, index) => element.archivo.toLowerCase().includes(filter.toLowerCase()))
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onDownloadFile (archivo) {
      console.log(archivo)
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.archivo)

      this.$notify({ message: `Descarga de archivo ${archivo.archivo}.` })
    }
  }
}
</script>
